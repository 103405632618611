import { useState } from "react";
import Header from "../components/Header"
import { formatDocument } from "../utils/Tools";
import { FaPlus } from "react-icons/fa6";
import ConvertApi from 'convertapi-js'
import { IoIosCloudCircle } from "react-icons/io";
import '../styles/Garantias.css';
import { IoIosCloudUpload } from 'react-icons/io';
import { formatFileSize } from "../utils/Tools";
import axios from "axios";
let convertApi = ConvertApi.auth('6ZpHgi8wokfcTpuT')


const Garantias = () => {
    const [garantia, setGarantia] = useState(true);
    //const [gTipo, setGTipo] = useState('');
    //const [gQuantidade, setGQuantidade] = useState('1');
    //const [gProprietario, setGProprietario] = useState('');
    //const [gDocumento, setGDocumento] = useState('');
    //const [gNatureza, setGNatureza] = useState('Pessoa Física');
    // const [gDescricao, setGDescricao] = useState('');
    const [documentFields, setDocumentFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [img, setImg] = useState([]);
    const garantiaDataString = localStorage.getItem('garantiaDataArray');
    // console.log(garantiaDataString,'garantiaDataString')
    const garantiaArrayInicial = garantiaDataString ? JSON.parse(garantiaDataString) : [{ gTipo: '', gQtd: '', gDesc: '', gProp: '', gNat: '', gCPF: '', gDoc2: '', gDoc: [] }];
    const [garantiaArray, setGarantiaArray] = useState(garantiaArrayInicial);
    // const [garantiaArray, setGarantiaArray] = useState([{ gTipo: '', gQtd: '', gDesc: '', gProp: '', gNat: '', gCPF: '', gDoc:[] }]);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleSocios = async (e) => {
        setLoading(true);
        const selectedFile = e.target.files[0];
        const params = convertApi.createParams();
        params.add('File', selectedFile);
        try {
            const result = await convertApi.convert('pdf', 'jpg', params);
            const imageUrls = result.dto.Files.map((file) => file.Url);
            setImg((prevImg) => [...prevImg, ...imageUrls]);
            localStorage.setItem('socios', JSON.stringify([...img, ...imageUrls]));
            setLoading(false);
        } catch (error) {
            console.error('Erro ao converter arquivo:', error);
            setLoading(false);
        }
    }
    /* const saveGarantiaToLocalStorage = () => {
         const garantiaData = {
             tipo: gTipo,
             natureza: gNatureza,
             descricao: gDescricao,
             proprietario: gProprietario,
             documento: gDocumento,
             quantidade: gQuantidade,
             documentos: documentFields,
         };
 
         // Converta o objeto para uma string JSON
         const garantiaDataString = JSON.stringify(garantiaData);
 
         // Salve a string JSON no localStorage
         localStorage.setItem('garantiaData', garantiaDataString);
         localStorage.setItem('garantia', true);
         window.alert('Garantia salva com sucesso!');
 
         setGarantia(false);
     };*/
    const saveGarantiaToLocalStorage = () => {
        const formData = new FormData();
        garantiaArray.map(function (garantia, i) {
            formData.append(`garantia[${i}][gTipo]`, garantia.gTipo);
            formData.append(`garantia[${i}][gQtd]`, garantia.gQtd);
            formData.append(`garantia[${i}][gDesc]`, garantia.gDesc);
            formData.append(`garantia[${i}][gProp]`, garantia.gProp);
            formData.append(`garantia[${i}][gNat]`, garantia.gNat);
            formData.append(`garantia[${i}][gCPF]`, garantia.gCPF);
            formData.append(`garantia[${i}][gDoc2]`, garantia.gDoc2);
        })
        formData.append(`i`, garantiaArray.length);
        axios.post(process.env.REACT_APP_BASE_URL + '/garantia', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                const garantiaDataString = JSON.stringify(response.data.createdGarantia.garantias);
                localStorage.setItem('garantiaDataArray', garantiaDataString);
                const garantiaIdString = JSON.stringify(response.data.createdGarantia._id);
                localStorage.setItem('garantiaIdString', garantiaIdString);
                window.alert('Garantia salva com sucesso!');

            })
            .catch((error) => {
                console.error('Erro ao enviar o arquivo para o servidor:', error);
            });

        //  setGarantia(false);
    };

    const removeLastDocumentField = () => {
        const updatedFields = [...documentFields];
        updatedFields.pop(); // Remove o último elemento do array
        setDocumentFields(updatedFields);
    };

    const addDocumentField = () => {
        setDocumentFields([...documentFields, ""]);
    };
    const addGarantia = () => {
        setGarantiaArray([...garantiaArray, { gTipo: '', gQtd: '', gDesc: '', gProp: '', gNat: '', gCPF: '', gDoc2: '', gDoc: [] }]);
    };
    const removeGarantia = (indexToRemove) => {
        console.log(garantiaArray.length, 'garantiaArray.length')
        if (garantiaArray.length > 1) {
            const updatedGarantia = [...garantiaArray];
            updatedGarantia.splice(indexToRemove, 1); // Remove o avalista pelo índice
            setGarantiaArray(updatedGarantia);
        }
    };
    const handleGarantiaChange = (e, index, field) => {
        const newGarantia = [...garantiaArray];
        newGarantia[index][field] = e.target.value;
        setGarantiaArray(newGarantia);
    };
    const handleFileChangeGarantia = (e, index) => {
        const selectedFiles = e.target.files;
        if (selectedFiles.length === 1) {
            const file = selectedFiles[0];
            const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg'];
            if (allowedFileTypes.includes(file.type)) {
                const newGarantia = [...garantiaArray];
                newGarantia[index].gDoc2 = file;
                setGarantiaArray(newGarantia);
                setSuccessMessage(true);
            } else {
                alert('Por favor, selecione um arquivo de imagem válido (PNG, JPEG, JPG).');
            }
        };
    }
    const handleRemoveFileGarantia = (garantiaIndex, fileIndex) => {
        const newGarantia = [...garantiaArray];
        newGarantia[garantiaIndex].gDoc2 = '';
        setGarantiaArray(newGarantia);
    };

    return (
        <div className="garantiasBody">
            <div className="fixed">
                <Header selected="garantias" />
            </div>
            <form className="garantiasDiv">
                <div className="divGarantia1">
                    <h2 className="h1Doc2">Insira as Informações de Garantia</h2>

                </div>
                {
                    garantia ?
                        garantiaArray.map((garantia, index) => (
                            <div className="boxGarantia">
                                <div className="divTitleDoc11">
                                    <h1 className="h1DocAva2"> {index + 1}° Garantia</h1>
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Tipo de Garantia(s):
                                    </label>
                                    <select
                                        className='selectDoc1'
                                        required
                                        value={garantia.gTipo || ''}
                                        onChange={(e) => handleGarantiaChange(e, index, 'gTipo')}
                                    >
                                        <option value="">Escolha as garantias</option>
                                        <option value="Veículo">Veículo</option>
                                        <option value="Imóvel">Imóvel</option>
                                        <option value="Maquinário">Maquinário</option>
                                        <option value="Duplicatas">Duplicatas</option>
                                        <option value="Recebíveis - Cartão de Crédito">Recebíveis - Cartão de Crédito</option>
                                        <option value="Outros">Outros</option>
                                    </select>
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Quantidade de bens:
                                    </label>
                                    <select
                                        className='selectDoc1'
                                        value={garantia.gQtd || ''}
                                        onChange={(e) => handleGarantiaChange(e, index, 'gQtd')}
                                    >
                                        <option value="" disabled>
                                            Selecione a quantidade
                                        </option>
                                        {[...Array(10)].map((_, index) => (
                                            <option key={index} value={index + 1}>
                                                {index + 1}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Descrição da(s) Garantia(s):
                                    </label>
                                    <textarea
                                        rows={4}  // Adjust the number of rows as needed
                                        className='inputTextarea' // Apply the new CSS class here
                                        placeholder="Ex: Ford Fusion 2017 Placa DEN1E15"
                                        value={garantia.gDesc}
                                        onChange={(e) => handleGarantiaChange(e, index, 'gDesc')}
                                    />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Proprietário Garantidor:
                                    </label>
                                    <input
                                        type="text"
                                        className='newInput'
                                        placeholder="Nome do Proprietário Garantidor"
                                        value={garantia.gProp}
                                        onChange={(e) => handleGarantiaChange(e, index, 'gProp')} />
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        Natureza do Garantidor:
                                    </label>
                                    <select
                                        className='selectDoc1'
                                        value={garantia.gNat || ''}
                                        onChange={(e) => handleGarantiaChange(e, index, 'gNat')}
                                    >
                                        <option value="">Escolha a Natureza</option>
                                        <option value="Pessoa Física">Pessoa Física</option>
                                        <option value="Pessoa Jurídica">Pessoa Jurídica</option>
                                    </select>
                                </div>
                                <div className="divInputsDoc">
                                    <label htmlFor="" className="labelInputsDoc">
                                        CPF/CNPJ do Garantidor:
                                    </label>
                                    <input
                                        type="text"
                                        className='newInput'
                                        placeholder=" CPF/CNPJ do Garantidor"
                                        value={formatDocument(garantia.gCPF)}
                                        onChange={(e) => handleGarantiaChange(e, index, 'gCPF')}
                                        maxLength={18} />
                                </div>
                                <div className="divInputsDoc" style={{ marginBottom: '2vh' }}>
                                    <label className='customFileInput2'>
                                        <input type="file" name="files" accept=".png, .jpg, .jpeg" onChange={(e) => handleFileChangeGarantia(e, index)} style={{ display: "none" }} />
                                        <IoIosCloudUpload className="uploadIcon" />
                                        <span className="uploadText" style={{ textAlign: 'center', width: "100%" }}>Documento de Garantia</span>
                                    </label>
                                </div>
                                {garantia.gDoc2 ? (
                                    <div className="uploadedFileItem" style={{ marginTop: '2vh' }}>
                                        <span>{garantia.gDoc2.name && 'Garantia'}</span>
                                        <span>{garantia.gDoc2.name}</span>
                                        <span>{garantia.gDoc2.size}</span>
                                        {garantia.gDoc2.name && <button onClick={() => handleRemoveFileGarantia(index, 'garantia')} className="button11"> Remover</button>}
                                    </div>
                                ) : (
                                    <div>
                                        {/* <span>Nenhum arquivo enviado ainda.</span>*/}
                                    </div>
                                )}
                                <div className="addButton" onClick={addGarantia} >+ Adicionar Garantia</div>
                                <div className="addButton" onClick={() => removeGarantia(index)} >- Remover Garantia</div>
                            </div>
                        ))
                        : <br />
                }
                <div className="divGarantia" style={{ display: 'flex', width: '100%' }}>

                    <div onClick={() => { saveGarantiaToLocalStorage() }} className="buttonSendGarantia">
                        Salvar Informações de Garantia
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Garantias